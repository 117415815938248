import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import Axios from "axios";
import {
  Box,
  Checkbox,
  FormLabel,
  TextField,
  Chip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import classes from "../MyVendor.module.css";
import { API } from "../../../configs/api";
import { ErrorContext } from "../../../context/ErrorContext";
import PreviewImages from "./PreviewImages/PreviewImages";
import Button from "../../UI/Button/Button";
import { MuiButton } from "../../Controls";
import { Add } from "@material-ui/icons";
import defaultImage from "../../../assets/images/imgplaceholder.jpg";

const defaultColor = "#000002";
const initialProduct = {
  id: "",
  name: "",
  description: "",  
  videoDemoUrl : "",
  videoDownloadLink :"",
  price: "",
  hasOffer: false,
  offer: 0,
  inStock: false,
  photos: [],
  tags: [],
  photoSrc: defaultImage,
  status: {
    id: "",
    name: "",
  },
  vendor: {
    id: "",
    name: "",
  },
  categories: [],
  subCategories: [],
};

const ProductForm = (props) => {
  const { id } = props.match.params;
  const { vendorId, notification, setNotification } = props;
  const [editMode, setEditMode] = useState(false);
  const [product, setProduct] = useState(initialProduct);
  const [productPhotos, setProductPhotos] = useState([]);
  const [newProductPhotos, setNewProductPhotos] = useState([]);
  const [productTags, setProductTags] = useState([]);
  const [tag, setTag] = useState({ id: "0", name: "" });
  const [allTags, setAllTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [imagePlaceHlder, _] = useState("");
  const viewError = useContext(ErrorContext).activateError;

  useEffect(() => {
    if (id) {
      if (isNaN(+id) || +id <= 0) {
        props.history.goBack();
      }

      Axios.get(API.products.GetProductFull + id)
        .then((response) => {
          setEditMode(true);
          const prod = { ...response.data };
          prod.videoDemoUrl = response.data.videoDemoUrl ? response.data.videoDemoUrl : '';
          prod.videoDownloadLink = response.data.videoDownloadLink ? response.data.videoDownloadLink : '';
          prod.description = response.data.description
            ? response.data.description
            : "";
          setProduct(prod);
          setProductPhotos(response.data.photos);
          setProductTags(response.data.tags);
        })
        .catch((error) => {
          viewError(true);
        });
    }
  }, [viewError, props.history, id]);

  useEffect(() => {
    // categories
    Axios.get(API.categories.withSub)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        viewError(true);
      });

    // tags
    Axios.get(API.products.tags)
      .then((response) => {
        setAllTags(response.data);
        // setAllTags([
        //   { id: "0", name: "opaa" },
        //   { id: "1", name: "opa" },
        //   { id: "2", name: "opaaa" },
        //   { id: "3", name: "opaaaa" },
        // ]);
      })
      .catch((error) => {
        viewError(true);
      });
  }, [viewError]);

  useEffect(() => {
    if (categories.length > 0 && product.categories) {
      const subCats = [];
      product.categories.forEach((category) => {
        const cat = categories.find((c) => c.id === +category.id);
        subCats.push(...cat.subCategories);
      });
      setSubCategories(subCats);
    }
  }, [categories, product.categories]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "category":
        setProduct({
          ...product,
          category: {
            ...product.category,
            id: value,
            name: e.target.options[e.target.selectedIndex].text,
          },
          subCategory: {
            id: "",
            name: "",
          },
        });
        break;

      case "subCategory":
        setProduct({
          ...product,
          subCategory: {
            ...product.subCategory,
            id: value,
            name: e.target.options[e.target.selectedIndex].text,
          },
        });
        break;

      case "hasOffer":
        setProduct({
          ...product,
          [name]: e.target.checked,
        });
        break;

      case "inStock":
        setProduct({
          ...product,
          [name]: e.target.checked,
        });
        break;

      default:
        setProduct({
          ...product,
          [name]: value,
        });
        break;
    }
  };

  const handleChangeAutocomplete = (name, value) => {
    switch (name) {
      case "categories":
        setProduct({
          ...product,
          categories: [...value],
          subCategories: [],
        });
        break;

      case "subCategories":
        setProduct({
          ...product,
          subCategories: [...value],
        });
        break;

      default:
        break;
    }
  };

  const showPreview = (e) => {
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (file) => {
        if (reader.readyState === 2) {
          setNewProductPhotos((prevState) => {
            const newPhotos = [...prevState];
            newPhotos.push({
              id: 0,
              name: file.target.result,
              photo: imageFile,
            });
            return newPhotos;
          });
        }
      };
      reader.readAsDataURL(imageFile);
    }
  };

  const handleChangeTag = (value) => {
    let tagObject;
    if (!value) {
      tagObject = { id: "0", name: "" };
    } else if (typeof value === "string") {
      tagObject = { id: "0", name: value };
    } else {
      tagObject = value;
    }
    setTag(tagObject);
  };

  const addTag = () => {
    if (tag.name.trim() !== "") {
      const newTags = [...productTags, tag];
      setProductTags(newTags);
      setTag({ id: "0", name: "" });
    }
  };

  const handleDeleteTag = (index) => {
    const newTags = [...productTags];
    newTags.splice(index, 1);
    setProductTags(newTags);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", product.name);    
    formData.append("videoDemoUrl", product.videoDemoUrl);
    formData.append("videoDownloadLink", product.videoDownloadLink);
    formData.append("description", product.description);
    formData.append("price", product.price);
    formData.append("hasOffer", product.hasOffer);
    formData.append("offer", product.offer);
    formData.append("inStock", product.inStock);
    formData.append("vendorId", vendorId);
    // formData.append("category.id", product.category.id);
    product.categories.forEach((category, index) =>
      formData.append(`categories[${index}].id`, category.id)
    );
    // formData.append("subCategory.id", product.subCategory.id);
    product.subCategories.forEach((subCategory, index) =>
      formData.append(`subCategories[${index}].id`, subCategory.id)
    );
    // formData.append("tags", productTags);
    productTags.forEach((tag, index) =>
      formData.append(`tags[${index}]`, tag.name)
    );
    // formData.append("photoFiles", product.photos);
    newProductPhotos.forEach((photo, index) =>
      formData.append("photoFiles", photo.photo)
    );

    if (!editMode) {
      Axios.post(API.products.route, formData)
        .then((response) => {
          
          setNotification({
            isOpen: true,
            message: "Product submitted and will be approved within 24 hours!",
            type: "success",
          });
          const currentPath = props.location.pathname;
          const parentPath = currentPath.substring(
            0,
            currentPath.lastIndexOf("/")
          );
          props.history.push(parentPath);
        })
        .catch((error) => {
          viewError(true);
        });
    } else {
      formData.append("id", product.id);
      Axios.put(API.products.route + product.id, formData)
        .then((response) => {
          
          setNotification({
            isOpen: true,
            message: "Product updated and will be approved within 24 hours!",
            type: "success",
          });
          const currentPath = props.location.pathname;
          const parentPath = currentPath.substring(
            0,
            currentPath.lastIndexOf("/")
          );
          props.history.push(parentPath);
        })
        .catch((error) => {
          viewError(true);
        });
    }
  };

  return (
    <div className={classes.VendorForm}>
      <div>
        <h2>{editMode ? "Edit Product" : "Add New Product"}</h2>
      </div>
      <div className="inputsContainer row">
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            <div className="row mt-5 justify-content-around align-items-center form-group">
              <div className="col-4">
                <div className="row">
                  <div className="col-12 p-3">
                    <label htmlFor="name">
                      <strong>Name</strong>
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      className="form-control"
                      value={product.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 p-3">
                    <label htmlFor="videoDemoUrl">
                      <strong>YouTube Video Code</strong>
                    </label>
                    <input
                      type="text"
                      id="videoDemoUrl"
                      name="videoDemoUrl"
                      placeholder="YouTube Video Code"
                      className="form-control"
                      value={product.videoDemoUrl}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 p-3">
                    <label htmlFor="videoDownloadLink">
                      <strong>Video Download Link</strong>
                    </label>
                    <input
                      type="text"
                      id="videoDownloadLink"
                      name="videoDownloadLink"
                      placeholder="Video Download Link"
                      className="form-control"
                      value={product.videoDownloadLink}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 p-3">
                    <Box width="100%">
                      {/* <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">Categories</FormLabel> */}
                      <Autocomplete
                        multiple
                        id="categories"
                        className={classes.White}
                        options={categories ? categories : []}
                        value={product.categories}
                        fullWidth
                        disableCloseOnSelect
                        getOptionSelected={(option, value) => option.id === +value.id}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              color="primary"
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Categories"
                            // placeholder="Categories"
                          />
                        )}
                        onChange={(event, value) =>
                          handleChangeAutocomplete("categories", value)
                        }
                      />
                      {/* </FormControl> */}
                    </Box>
                  </div>
                  <div className="col-12 p-3">
                    <Box width="100%">
                      {/* <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">SubCategories</FormLabel> */}
                      <Autocomplete
                        multiple
                        id="subCategories"
                        className={classes.White}
                        options={subCategories ? subCategories : []}
                        value={product.subCategories}
                        fullWidth
                        disableCloseOnSelect
                        getOptionSelected={(option, value) => option.id === +value.id}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              color="primary"
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="SubCategories"
                            // placeholder="SubCategories"
                          />
                        )}
                        onChange={(event, value) =>
                          handleChangeAutocomplete("subCategories", value)
                        }
                      />
                      {/* </FormControl> */}
                    </Box>
                  </div>
                  <div className="col-12 p-3">
                    <label htmlFor="price">
                      <strong>Price</strong>
                    </label>
                    <input
                      type="number"
                      id="price"
                      name="price"
                      min="0"
                      placeholder="Price"
                      className="form-control"
                      value={product.price}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 p-3">
                    <div className="row align-items-center">
                      <div className="col-5">
                        <div className="form-check d-flex align-items-center">
                          <input
                            type="checkbox"
                            id="hasOffer"
                            name="hasOffer"
                            className="form-check-input"
                            checked={product.hasOffer}
                            onChange={handleChange}
                          />
                          <label
                            htmlFor="hasOffer"
                            className="form-check-label ml-2"
                          >
                            <strong>Has Offer</strong>
                          </label>
                        </div>
                      </div>
                      <div className="col-7 input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">Offer Amount</span>
                        </div>
                        <input
                          type="number"
                          id="offer"
                          name="offer"
                          min="0"
                          className="form-control"
                          value={product.offer}
                          onChange={handleChange}
                          disabled={!product.hasOffer}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-3">
                    <div className="row align-items-center">
                      <div className="col-5">
                        <div className="form-check d-flex align-items-center">
                          <input
                            type="checkbox"
                            id="inStock"
                            name="inStock"
                            className="form-check-input"
                            checked={product.inStock}
                            onChange={handleChange}
                          />
                          <label
                            htmlFor="inStock"
                            className="form-check-label ml-2"
                          >
                            <strong>In Stock</strong>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-7">
                <div className="card text-center">
                  <div className={classes.CoverCard}>
                    <img
                      src={defaultImage}
                      className="card-img-top"
                      alt="product"
                    />
                  </div>
                  <div className="card-body">
                    <div className="input form-group">
                      <label htmlFor="photo">
                        <strong>Photo</strong>
                      </label>
                      <input
                        type="file"
                        id="photo"
                        name="photo"
                        accept=".jpg, .jpeg, .png"
                        className="form-control-file"
                        onChange={showPreview}
                        required={
                          !(
                            newProductPhotos.length > 0 ||
                            productPhotos.length > 0
                          )
                        }
                        value={imagePlaceHlder}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 p-3">
                <label htmlFor="description">
                  <strong>Description</strong>
                </label>
                <input
                  type="text"
                  id="description"
                  name="description"
                  placeholder="Description"
                  className="form-control"
                  value={product.description}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 d-flex">
                <Autocomplete
                  id="add-tag"
                  inputValue={tag.name}
                  freeSolo
                  options={allTags}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    // Regular option
                    return option.name;
                  }}
                  renderOption={(option) => option.name}
                  onChange={(event, value) => handleChangeTag(value)}
                  style={{ width: 300, backgroundColor: "#FFF" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // value={tag.name}
                      onChange={(event) => handleChangeTag(event.target.value)}
                      label="Tag"
                      variant="outlined"
                    />
                  )}
                />
                <MuiButton
                  text="Add"
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={addTag}
                />
              </div>
              <div className="col-12 m-3">
                {productTags.map((tag, index) => (
                  <Chip
                    key={`${tag.name}-${index}`}
                    label={tag.name}
                    onDelete={() => handleDeleteTag(index)}
                    className={"m-2"}
                  />
                ))}
              </div>
              {editMode && product.photos.length > 0 && (
                <div className="col-12 mt-3">
                  <PreviewImages
                    photos={productPhotos}
                    setPhotos={setProductPhotos}
                    isStored={true}
                  />
                </div>
              )}
              <div className="col-12 mt-3">
                <PreviewImages
                  photos={newProductPhotos}
                  setPhotos={setNewProductPhotos}
                />
              </div>
            </div>
            <div className="row mt-5 justify-content-center">
              <div className="col-12 text-center">
                <Button type="submit">
                  <strong>{editMode ? "Update Product" : "Add Product"}</strong>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ProductForm);
