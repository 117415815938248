import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { Chip, Paper } from "@material-ui/core";
import {Helmet} from "react-helmet";
import classes from "./Product.module.css";
import { API } from "../../configs/api";
import { AuthContext } from "../../context/AuthContext";
import { CartContext } from "../../context/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as emptyHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as fullHeart } from "@fortawesome/free-solid-svg-icons";

//import viewsIcon from '../../assets/images/views-count-icon.png';
import AreaBar from "../AreaBar/AreaBar";

const initialProduct = {
  id: "",
  name: "",
  videoDemoUrl : "",
  videoDownloadLink :"",
  description: "",
  price: "",
  hasOffer: false,
  offer: 0,
  viewsCount: 0,
  inStock: true,
  photos: [],
  tags: [],
  category: {
    id: "",
    name: "",
  },
  subCategory: {
    id: "",
    name: "",
  },
};
let AllTags ="";
const Product = (props) => {
  const { productId } = props.match.params;
  
  const [product, setProduct] = useState(initialProduct);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [count, setCount] = useState(1);
  const [isLoved, setIsLoved] = useState(false);
  const userId = useContext(AuthContext).userData.id;
  const isAuthenticated = useContext(AuthContext).isAuthenticated;
  const wishlist = useContext(AuthContext).wishlist;
  const addCartProduct = useContext(CartContext).addCartProduct;
  const addToWishlist = useContext(AuthContext).addToWishlist;
  const removeFromWishlist = useContext(AuthContext).removeFromWishlist;

  useEffect(() => {
    if (productId) {
      if (isNaN(+productId) || +productId <= 0) {
        props.history.goBack();
      }

      Axios.get(API.products.route + productId)
        .then((response) => {
          const prod = { ...response.data };
          prod.tags.forEach((element,index) => {
            AllTags += element.name;
            if(index != prod.tags.length -1){
              AllTags += ", "
            }
          });
          //document.title = "Slick Elements » Product » "+ prod.name;
          prod.finalPrice = response.data.hasOffer
            ? response.data.price - response.data.offer
            : response.data.price;
          setProduct(prod);
          setSelectedPhoto(response.data.photos[0]);
        })
        .catch((error) => {});
    }
  }, [productId, props.history]);

  useEffect(() => {
    if (wishlist.length > 0) {
      const alreadyExists =
        wishlist.findIndex((w) => w.productId === product.id) >= 0;
      setIsLoved(alreadyExists);
    } else {
      setIsLoved(false);
    }
  }, [wishlist, product.id]);


  const addToCartHandler = () => {
    if (userId) {
      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("productId", product.id);
      formData.append("count", count);
      Axios.post(API.users.addToCart, formData)
        .then((response) => {})
        .catch((error) => {});
    }
    const cartProduct = {
      productId: product.id,
      count: count,
    };
    addCartProduct(cartProduct);
    
  };

  const addToWishlistHander = () => {
    if (isAuthenticated) {
      if (isLoved) {
        Axios.delete(`${API.users.removeFromWishlist}${userId}/${product.id}`)
          .then((response) => {
            setIsLoved(false);
          })
          .catch((error) => {});
        removeFromWishlist(product.id);
      } else {
        const formData = new FormData();
        formData.append("userId", userId);
        formData.append("productId", product.id);
        Axios.post(API.users.addToWishlist, formData)
          .then((response) => {
            setIsLoved(true);
          })
          .catch((error) => {});
        const wishlistItem = {
          userId: userId,
          productId: product.id,
        };
        addToWishlist(wishlistItem);
      }
    }
  };

  return (
    <React.Fragment>
      <AreaBar title="Product" area="Product" />
      <Paper className={classes.Product} elevation={0}>
        <div className="container py-5">
          <div className="row">
            <div className="col-12 col-md-6">
              <div>
                {/* <img
                  src={selectedPhoto?.name}
                  alt={product.name}
                  className="img-fluid"
                /> */}
              </div>
              <iframe
                  className="modal__video-style"
                  loading="lazy"
                  width="100%"
                  height="282"
                  src={"https://www.youtube.com/embed/" + product.videoDemoUrl + "?rel=0"}
                  title={product.name}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
            </div>

            <div className="col-12 col-md-6">
              <div className="row justify-content-center">
                <div className="col-12">
                  <ul className="mx-5 px-5">
                    <li>
                      <h2 className="mb-3 text-uppercase">{product.name}</h2>
                      <div className="mb-3 d-flex align-items-center text-uppercase">
                        <h3 className="mr-3">{product.finalPrice + " EGP"}</h3>
                        {product.hasOffer ? (
                          <h4 className={classes.DelPrice}>
                            <del>{product.price + " EGP"}</del>
                          </h4>
                        ) : null}
                      </div>
                      {product.color && (
                        <div className="mb-3">
                          <div
                            className={classes.ItemColor}
                            style={{
                              backgroundColor: product.color
                                ? product.color
                                : "transparent",
                              visibility: product.color ? "initial" : "hidden",
                            }}
                          ></div>
                        </div>
                      )}
                    </li>
                    <li>
                      <div>
                        <button
                          className={classes.Btn}
                          onClick={addToCartHandler}
                        >
                          <strong>Download</strong>
                        </button>
                      </div>
                    </li>
                    {isAuthenticated && (
                      <li>
                        <div
                          className="d-flex pointer"
                          onClick={addToWishlistHander}
                        >
                          <h3 className={["pr-3", classes.Icon].join(" ")}>
                            {isLoved ? (
                              <FontAwesomeIcon icon={fullHeart} />
                            ) : (
                              <FontAwesomeIcon icon={emptyHeart} />
                            )}
                          </h3>
                          <h3>
                            {isLoved
                              ? "Remove From Wishlist"
                              : "Add To Wishlist"}
                          </h3>
                        </div>
                      </li>
                    )}
                    {product.description && (
                      <li>
                        <h3>{product.description}</h3>
                      </li>
                    )}
                    {product.tags.length > 0 && (
                      <li>
                        {product.tags.map((tag) => (
                          <Chip
                            key={tag.id}
                            label={tag.name}
                            clickable
                            color="primary"
                            variant="outlined"
                            className="mr-3"
                          /> 
                        ))}
                        <Helmet>
                          <title>{product.name} » Slick Elements - Stock Footage</title>
                          <meta name="description" content={product.description} />
                          <meta name="keywords" content={AllTags}/>
                        </Helmet>
                      </li>
                    )}
                    {product.vendor && (
                      <li>
                        <h3>
                          <Link to={`/vendor/${product.vendor.id}`}>
                            Vendor: {product.vendor.name}
                          </Link>
                        </h3>
                      </li>
                    )}
                    
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default Product;
