import React from "react";
import { Paper } from "@material-ui/core";
import classes from "../Checkout.module.css";
import AreaBar from "../../AreaBar/AreaBar";
import thankyou from '../../../assets/images/thank-you.png';

const ThanksMessage = (props) => {
  return (
    <div>
    <AreaBar title="Order Created Successfully" area="Tank You" />
      <Paper className={classes.Checkout} elevation={0}>
      <div className="container py-5">
        <div className="inputsContainer row">
          <div className="col-12">
            <div className={classes.Box}>
              
              <div className="row my-5">
                <div className="col-12">
                  <div className="form-group">
                    <img width="100%" src={thankyou} />    
                    <p className={[classes.centeredtext, classes.MarginTopXLarg, classes.TanksMessageTxt].join(' ')}>
                    Thank you for using Slick Elements to order our one of a kind products, your order is being processed, Once the order's payment is confirmed, the download link(s) will be sent to your e-mail
                    <br/>
                    Also, if you are registered on the website, you can find the download link in your Orders list
                    <br/> 
                    شكرا لاستخدامك سليك اليمنتس لطلب احدى منتجاتنا الفريدة، طلبك قيد التنفيذ، بمجرد تأكيد عملية الدفع
                    <br/>
                    <h3 style={{ fontSize: "18px", marginTop: "8px", fontWeight:"bold", color: "#cf0909", direction:"rtl" }}> رابط التحميل سنرسله لك على البريد الإلكتروني</h3>
                    ايضا،ا ذا كنت قمت بتسجيل الدخول على الموقع،  يمكنك الحصول على رابط التحميل من قائمة طلباتك
                    </p>                
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </Paper>
    </div>
  );
};

export default React.memo(ThanksMessage);
