import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt } from "react-icons/bi";
import classes from "./ProductCard.module.css";
import { API } from "../../../configs/api";
import { AuthContext } from "../../../context/AuthContext";
import { CartContext } from "../../../context/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faEye, faPlay } from "@fortawesome/free-solid-svg-icons";
import { faHeart as emptyHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as fullHeart } from "@fortawesome/free-solid-svg-icons";
import freeTag from '../../../assets/images/free-tag.png';

const ProductCard = (props) => {
  //const [selectedSize, setSelectedSize] = useState(props.product.sizes[0].id);
  const [isLoved, setIsLoved] = useState(false);
  const userId = useContext(AuthContext).userData.id;
  const isAuthenticated = useContext(AuthContext).isAuthenticated;
  const wishlist = useContext(AuthContext).wishlist;
  const addCartProduct = useContext(CartContext).addCartProduct;
  const addToWishlist = useContext(AuthContext).addToWishlist;
  const removeFromWishlist = useContext(AuthContext).removeFromWishlist;

  const [modal, setModal] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);

  const openModal = () => {
    setModal(!modal);
  };

  const spinner = () => {
    setVideoLoading(!videoLoading);
  };
  
  const finalPrice = props.product.hasOffer
    ? props.product.price - props.product.offer
    : props.product.price;

  useEffect(() => {
    if (wishlist.length > 0) {
      const alreadyExists =
        wishlist.findIndex((w) => w.productId === props.product.id) >= 0;
      setIsLoved(alreadyExists);
    } else {
      setIsLoved(false);
    }
  }, [wishlist, props.product.id]);

  const addToCartHandler = () => {
    if (userId) {
      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("productId", props.product.id);
      //formData.append("sizeId", selectedSize);
      formData.append("count", 1);
      Axios.post(API.users.addToCart, formData)
        .then((response) => {
        })
        .catch((error) => {
        });
    }
    const cartProduct = {
      productId: props.product.id,
      //sizeId: selectedSize,
      count: 1,
    };
    addCartProduct(cartProduct);
  };

  const addToWishlistHander = () => {
    if (isAuthenticated) {
      if (isLoved) {
        Axios.delete(
          `${API.users.removeFromWishlist}${userId}/${props.product.id}`
        )
          .then((response) => {
            setIsLoved(false);
          })
          .catch((error) => {
          });
        removeFromWishlist(props.product.id);
      } else {
        const formData = new FormData();
        formData.append("userId", userId);
        formData.append("productId", props.product.id);
        Axios.post(API.users.addToWishlist, formData)
          .then((response) => {
            setIsLoved(true);
          })
          .catch((error) => {
          });
        const wishlistItem = {
          userId: userId,
          productId: props.product.id,
        };
        addToWishlist(wishlistItem);
      }
    }
  };

  return (
    <div className={classes.ProductCard}>
      <div className={classes.CardContent}>
        <div className={classes.CardCover}>
          <div className={classes.CardImg}>
            <img
              src={props.product.photos[0]?.name}
              alt={props.product.name}
              className="img-fluid"
            />
            {/* {props.product.price==0 ? (
              <div className={classes.freeTag}>
              <img width="70px" src={freeTag} />
            </div>
            ) : null} */}
            <div className={classes.palyVideo} onClick={openModal}>
              <FontAwesomeIcon icon={faPlay} />
            </div>
            
          </div>
          <div className={classes.Actions}>

            <div className={classes.Action} onClick={addToCartHandler}>
              <FontAwesomeIcon icon={faShoppingCart} />
            </div>
            {isAuthenticated ? (
              <div className={classes.Action} onClick={addToWishlistHander}>
                {isLoved ? (
                  <FontAwesomeIcon icon={fullHeart} />
                ) : (
                  <FontAwesomeIcon icon={emptyHeart} />
                )}
              </div>
            ) : null}
          </div>
        </div>

        <div className={classes.CardInfo}>
          <Link to={"/product/" + props.product.id}>
            <h2>{props.product.name}

            </h2>
          </Link>
          <div className="d-flex align-items-center text-uppercase">
            {props.product.price==0 ? (
              <h3 className={classes.freeprice}>Free</h3>
            ) : (<h3 className="mr-3">{finalPrice + " EGP"}</h3>)}
            
            {props.product.hasOffer ? (
              <h4 className={classes.DelPrice}>
                <del>{props.product.price + " EGP"}</del>
              </h4>
            ) : null}
          </div>
          {/* <div
            className={[classes.ItemColor, "mb-2"].join(" ")}
            style={{
              backgroundColor: props.product.color
                ? props.product.color
                : "transparent",
              visibility: props.product.color ? "initial" : "hidden", "float": "left"
            }}
          ></div>
          <div style={{ "float": "right" }}>
            <FontAwesomeIcon icon={faEye} /> {props.product.viewsCount}
          </div> */}
          <br clear="all" />
          <button onClick={openModal} className={classes.modalbtt}>
            {modal ? (
              <section className="modal__bg">
                <div className="modal__align">
                  <div className="modal__content" modal={modal}>
                    <IoCloseOutline
                      className="modal__close"
                      arial-label="Close modal"
                      onClick={setModal}
                    />
                    <div className="modal__video-align">
                      {videoLoading ? (
                        <div className="modal__spinner">
                          <BiLoaderAlt
                            className="modal__spinner-style"
                            fadeIn="none"
                          />
                        </div>
                      ) : null}
                      <iframe
                        className="modal__video-style"
                        onLoad={spinner}
                        loading="lazy"
                        width="800"
                        height="500"
                        src={ "https://www.youtube.com/embed/" + props.product.videoDemoUrl+ "?rel=0"}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </section>
            ) : null}
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProductCard);
