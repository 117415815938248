import React, { useState, useCallback } from "react";

export const CartContext = React.createContext({
  cart: [
    {
      productId: 0,
      sizeId: 0,
      count: 0,
    },
  ],
  totalCount: 0,
  OldCount: 0,
  setCart: (cart) => {},
  addCartProduct: (cartProduct) => {},
  updateProductCount: (index, count) => {},
  removeCartProduct: (index) => {},
  clearCart: () => {},
});

const CartContextProvider = (props) => {
  const [cart, setCart] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [OldCount, setOldCount] = useState(0);
  const setCartHandler = useCallback((cartProducts) => {
    let count = 0;
    for (let i = 0; i < cartProducts.length; i++) {
      const element = cartProducts[i];
      count += element.count;
    }
    setCart(cartProducts);
    setTotalCount(count);
    setOldCount(count)
    localStorage.setItem("cart", JSON.stringify(cartProducts));
  }, []);

  const addCartProductHandler = (cartProduct) => {
    const alreadyExists =
      cart.findIndex(
        (p) =>
          p.productId === cartProduct.productId
      ) >= 0;
    if (!alreadyExists) {
      let newCount = totalCount;
      let OldCount = totalCount;
      newCount += cartProduct.count;

      const newCart = [];
      cart.forEach((product) => {
        newCart.push({ ...product });
      });
      newCart.push({ ...cartProduct });

      setCart(newCart);
      setTotalCount(newCount);
      setOldCount(OldCount)
      localStorage.setItem("cart", JSON.stringify(newCart));
    }
  };

  const updateProductCountHandler = (productId, count) => {
    const index = cart.findIndex(
      (p) => p.productId === productId
    );

    let newCount = totalCount;    
    let OldCount = totalCount;
    newCount -= cart[index].count;
    newCount += count;

    const newCart = [];
    cart.forEach((product) => {
      newCart.push({ ...product });
    });
    newCart[index].count = count;

    setCart(newCart);
    setTotalCount(newCount);
    setOldCount(OldCount)
    localStorage.setItem("cart", JSON.stringify(newCart));
  };

  const removeCartProductHandler = (productId) => {
    const index = cart.findIndex(
      (p) => p.productId === productId
    );
    let newCount = totalCount;    
    let OldCount = totalCount;
    newCount -= cart[index].count;

    const newCart = [];
    cart.forEach((product) => {
      newCart.push({ ...product });
    });
    newCart.splice(index, 1);

    if (newCart.length <= 0) {
      clearCart();
      return;
    }

    setCart(newCart);
    setTotalCount(newCount);
    setOldCount(OldCount);
    localStorage.setItem("cart", JSON.stringify(newCart));
  };

  const clearCart = useCallback(() => {
    setCart([]);
    setTotalCount(0);
    localStorage.removeItem("cart");
  }, []);

  return (
    <CartContext.Provider
      value={{
        cart: cart,
        totalCount: totalCount,
        OldCount : OldCount,
        setCart: setCartHandler,
        addCartProduct: addCartProductHandler,
        updateProductCount: updateProductCountHandler,
        removeCartProduct: removeCartProductHandler,
        clearCart: clearCart,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
