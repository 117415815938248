import React from "react";
import { Link } from "react-router-dom";

import classes from "./CategoryCard.module.css";

const CategoryCard = (props) => {
  
  let url ="";
  if(props.category.id ==1){
    url ="/category/" + props.category.id
  }
  else{
    url = "/subcategory/" + (props.category.id -1);
  }
  return (
    <Link to={url}>
      <div className={classes.CategoryCard}>
        <div className={classes.CardContent}>
          <div className={classes.CardCover}>
            <img
              src={props.category.cover}
              alt={props.category.name}
              className="img-fluid"
            />
          </div>
          <div className={classes.CardOverlay}>
            <div className={classes.CardOverlayCover}></div>
            <div className={classes.CardOverlayTitle}>
              <h3>{props.category.name}</h3>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CategoryCard;
